import * as React from 'react';
import { ProductPage } from '../components/ProductPage';

const productDescription = `
<h2>Konsultacje podatkowe / prawne (<i>492 zł/h</i>)</h2>
`;

export default () => {
  return (
    <ProductPage
      productId="1"
      initialPrice={492}
      productDescription={productDescription}
    />
  );
};
